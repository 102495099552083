import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { ContactDetails } from '../components/contactDetails'
import { SmallPrint } from '../components/smallPrint'
import { PageTitle, CtaGiftVoucher } from '../components/common'
import { SouthernCross } from '../components/southernCross'
import { ContentContainer, Row, VerticalLine } from '../components/utils'
import { useGeneralData } from '../hooks'

const WidgetWrapper = styled.div`
  display: block;
  margin: 0 auto;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 480px;
  }
`

const Warning = styled.div`
  background-color: #fc5c65;
  color: #fff;
  text-align: center;
  padding: 8px 8px;
  border-radius: 3px;
  margin-bottom: 20px;
  margin: 0 auto 20px auto;
  max-width: 480px;
`

const BookPage = ({ location }) => {
  const { generalData } = useGeneralData()
  return (
    <Layout location={location}>
      <SEO
        title="Book"
        description="Get relief from persistent everyday pain or help injury recovery by booking a massage with us today."
      />
      <PageTitle title="Book a Massage" />
      <ContentContainer py="md">
        <Row col={2} gap={100}>
          <div>
            <WidgetWrapper>
              <iframe
                src={`//hamiltonmassagecompany.gettimely.com/book/embed?location=138598${location.state &&
                  location.state.productId}&staff=256057`}
                title="Hamilton Massage Company booking widget"
                scrolling="no"
                id="timelyWidget"
                css={`
                  width: 100%;
                  height: 600px;
                  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
                  border-radius: 10px;
                  border: none;
                  padding: 20px;
                  margin-bottom: 40px;
                `}
              ></iframe>
              <SmallPrint>
                <em>
                  {generalData.creditCard}
                  <br />
                  {generalData.cancellationPolicy}
                </em>
              </SmallPrint>
            </WidgetWrapper>
          </div>
          <VerticalLine bg="rgba(244, 244, 244, 1)">
            <div />
          </VerticalLine>
          <ContactDetails />
        </Row>
        <SouthernCross />
      </ContentContainer>
      <CtaGiftVoucher />
    </Layout>
  )
}

export default BookPage
