import React from 'react'
import styled from 'styled-components'

import SouthernCrossAsset from '../images/SouthernCross_MemberCard.jpg'

const SouthernCrossWrapper = styled.div`
  padding-top: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  background: #fff;
  border-radius: 5px;

  > h4 {
    margin-left: 40px;
    margin-bottom: 0;
  }

  > img {
    width: 150px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    text-align: center;

    > h4 {
      margin-left: 0;
      margin-top: 20px;
      margin-bottom: 50px;
    }
  }
`

export const SouthernCross = () => {
  return (
    <SouthernCrossWrapper>
      <img src={SouthernCrossAsset} alt="Southern Cross | Easy Claim | Hamilton Massage Company"></img>
      <h4>Southern Cross Health Society Easy-claim available for members.</h4>
    </SouthernCrossWrapper>
  )
}
