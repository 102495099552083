import React from 'react'
import styled from 'styled-components'

import { useContactData } from '../hooks'
import FBLogoBlack from '../images/HMC_FBIcon_Black.png'

const ContactDetailsWrapper = styled.div`
  p {
    color: #999;
  }

  h4 {
    margin-bottom: ${props => props.theme.spacing.sm};

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      margin-bottom: 30px;
    }
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
  }

  h4 {
    a {
      display: flex;
      align-items: center;

      img {
        margin-left: 7px;
        width: 20px;
      }
    }
  }

  iframe {
    margin-top: 40px;
    width: 100%;
    height: 380px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding-top: ${props => props.theme.spacing.sm};
  }
`

export const ContactDetails = () => {
  const { contactData } = useContactData()
  return (
    <ContactDetailsWrapper>
      <p>Email</p>
      <h4>
        <a href={`mailto:${contactData.email}`}>{contactData.email}</a>
      </h4>
      <p>Phone</p>
      <h4>{contactData.phone}</h4>
      <h4>
        <a target="_blank" rel="noopener noreferrer" href={contactData.facebook}>
          {contactData.facebookText} <img src={FBLogoBlack} alt={contactData.facebookText} />
        </a>
      </h4>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.017426085085!2d175.29019541573572!3d-37.78963137975681!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d6d18b7fa232221%3A0xed9af4482627669a!2s14+Beale+St%2C+Hamilton+East%2C+Hamilton+3216!5e0!3m2!1sen!2snz!4v1561447949002!5m2!1sen!2snz"
        title="Hamilton Massage Company Location"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
      ></iframe>
    </ContactDetailsWrapper>
  )
}
